import mitt from 'mitt'

// interface AuthedMessage extends WSBaseMessage {
//   httpSessionId: string
// }

interface SubscribeDeviceMessage extends WSBaseMessage {
  deviceCodes: string[]
}

interface PushPlatformEventDisappearMessage extends WSBaseMessage {
  event: HistoryPlatformEvent
}

interface Events {
  onSubscribeDevice: SubscribeDeviceMessage
  onDeviceIconChenged: any
  onVideo: any
  onDeviceOnOffLine: any
  onPushPlatformEventDisappear: PushPlatformEventDisappearMessage
  onPushPlatformEventTrigger: PushPlatformEventDisappearMessage
  onLogout: any
}

const emitter = mitt<Events>()

export default emitter
